import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardText, Row, Col, Button, Input, FormGroup, Label, FormFeedback } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faX, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import CountryDropdown from '../atoms/inputs/CountryDropdown';

const CustomerShippingAccountsSection = ({ account, quoteToolClient, onAccountUpdated }) => {
    const [customerShippingAccounts, setCustomerShippingAccounts] = useState(
        account.meta.customerShippingAccounts || []
    );
    const [editingIndex, setEditingIndex] = useState(null);
    const [newCustomerShippingAccount, setNewCustomerShippingAccount] = useState(null);

    const handleAddNewCustomerShippingAccount = () => {
        setNewCustomerShippingAccount({
            accountNumber: '',
            carrier: '',
            country: '',
            postalCode: '',
        });
        setEditingIndex('new');
    };

    const handleFieldChange = (index, field, value) => {
        if (index === 'new') {
            setNewCustomerShippingAccount({
                ...newCustomerShippingAccount,
                [field]: value,
            });
        } else {
            const updatedCustomerShippingAccounts = [...customerShippingAccounts];
            updatedCustomerShippingAccounts[index][field] = value;
            setCustomerShippingAccounts(updatedCustomerShippingAccounts);
        }
    };

    const handleSaveCustomerShippingAccount = async () => {
        const customerShippingAccountToSave =
            editingIndex === 'new'
                ? newCustomerShippingAccount
                : customerShippingAccounts[editingIndex];

        if (!validateCustomerShippingAccount(customerShippingAccountToSave)) {
            toast.error('All fields are required.');
            return;
        }

        try {
            const updatedMeta = {
                ...account.meta,
                customerShippingAccounts:
                    editingIndex === 'new'
                        ? [...customerShippingAccounts, customerShippingAccountToSave]
                        : customerShippingAccounts,
            };

            const savedAccount = await quoteToolClient.patchAccount({
                accountId: account.id,
                patch: { meta: updatedMeta },
            });

            onAccountUpdated(savedAccount);
            setCustomerShippingAccounts(updatedMeta.customerShippingAccounts);
            setEditingIndex(null);
            setNewCustomerShippingAccount(null);
            toast.success('Customer shipping account saved successfully!');
        } catch (error) {
            toast.error('Failed to save customer shipping account.');
            console.error(error);
        }
    };

    const validateCustomerShippingAccount = (customerShippingAccount) => {
        return (
            customerShippingAccount.accountNumber.trim() &&
            customerShippingAccount.carrier.trim() &&
            customerShippingAccount.country.trim() &&
            customerShippingAccount.postalCode.trim()
        );
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
        setNewCustomerShippingAccount(null);
    };

    return (
        <Row>
            <Col xs="12" className="d-flex justify-content-between align-items-center mb-2">
                <h3>Customer Shipping Accounts</h3>
                <Button
                    color="primary"
                    outline
                    onClick={handleAddNewCustomerShippingAccount}
                >
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} /> Add Shipping Account
                </Button>
            </Col>
            <Row>
                {customerShippingAccounts.map((customerShippingAccount, index) => (
                    <Col sm="6" md="4" key={index}>
                        <Card className="compact-card mb-3">
                            <CardBody>
                                <CardTitle className="d-flex justify-content-between align-items-center">
                                    <span>Shipping Account {index + 1}</span>
                                    {editingIndex !== index && (
                                        <FontAwesomeIcon
                                            icon={faPencilAlt}
                                            className="edit-icon"
                                            onClick={() => setEditingIndex(index)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                </CardTitle>
                                {editingIndex === index ? (
                                    <>
                                        <FormGroup>
                                            <Label>Account Number</Label>
                                            <Input
                                                value={customerShippingAccount.accountNumber}
                                                onChange={(e) =>
                                                    handleFieldChange(index, 'accountNumber', e.target.value)
                                                }
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Carrier</Label>
                                            <Input
                                                value={customerShippingAccount.carrier}
                                                onChange={(e) =>
                                                    handleFieldChange(index, 'carrier', e.target.value)
                                                }
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Country</Label>
                                            <CountryDropdown
                                                selectedCountry={customerShippingAccount.country}
                                                onCountryChange={({ country }) =>
                                                    handleFieldChange(index, 'country', country)
                                                }
                                                defaultCountry={"US"} //TODO Default correctly
                                                isSubdivision={false}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Postal Code</Label>
                                            <Input
                                                value={customerShippingAccount.postalCode}
                                                onChange={(e) =>
                                                    handleFieldChange(index, 'postalCode', e.target.value)
                                                }
                                            />
                                        </FormGroup>
                                        <div className="d-flex justify-content-between mt-2">
                                            <Button
                                                color="success"
                                                size="sm"
                                                outline
                                                onClick={handleSaveCustomerShippingAccount}
                                            >
                                                <FontAwesomeIcon icon={faSave} /> Save
                                            </Button>
                                            <Button size="sm"
                                                    outline
                                                    color="danger"
                                                    onClick={handleCancelEdit}>
                                                <FontAwesomeIcon icon={faX} /> Cancel
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <CardText>
                                            <strong>Account Number:</strong> {customerShippingAccount.accountNumber}
                                        </CardText>
                                        <CardText>
                                            <strong>Carrier:</strong> {customerShippingAccount.carrier}
                                        </CardText>
                                        <CardText>
                                            <strong>Country:</strong> {customerShippingAccount.country}
                                        </CardText>
                                        <CardText>
                                            <strong>Postal Code:</strong> {customerShippingAccount.postalCode}
                                        </CardText>
                                    </>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                ))}
                {newCustomerShippingAccount && (
                    <Col sm="6" md="4">
                        <Card className="compact-card mb-3">
                            <CardBody>
                                <FormGroup>
                                    <Input
                                        placeholder="Account Numnber"
                                        value={newCustomerShippingAccount.accountNumber}
                                        onChange={(e) =>
                                            handleFieldChange('new', 'accountNumber', e.target.value)
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        placeholder="Carrier"
                                        value={newCustomerShippingAccount.carrier}
                                        onChange={(e) =>
                                            handleFieldChange('new', 'carrier', e.target.value)
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <CountryDropdown
                                        selectedCountry={newCustomerShippingAccount.country}
                                        onCountryChange={({ country }) =>
                                            handleFieldChange('new', 'country', country)
                                        }
                                        defaultCountry={"US"} //TODO Default correctly
                                        isSubdivision={false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        placeholder="Postal Code"
                                        value={newCustomerShippingAccount.postalCode}
                                        onChange={(e) =>
                                            handleFieldChange('new', 'postalCode', e.target.value)
                                        }
                                    />
                                </FormGroup>
                                <div className="d-flex justify-content-between mt-2">
                                    <Button
                                        color="success"
                                        size="sm"
                                        outline
                                        onClick={handleSaveCustomerShippingAccount}
                                    >
                                        <FontAwesomeIcon icon={faSave} /> Save
                                    </Button>
                                    <Button color="danger"    size="sm"
                                            outline onClick={handleCancelEdit} >
                                        <FontAwesomeIcon icon={faX} /> Cancel
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </Row>
        </Row>
    );
};

CustomerShippingAccountsSection.propTypes = {
    account: PropTypes.object.isRequired,
    quoteToolClient: PropTypes.object.isRequired,
    onAccountUpdated: PropTypes.func.isRequired,
};

export default CustomerShippingAccountsSection;
